.App {
  text-align: center;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  display: flex;
  justify-content: center;
}

.nav-buttons {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.page-button {
  font-weight: bold;
  background-color: #0e2f59;
  border: none;
  color: white;
  margin-right: 1rem;
}

.page-button:hover {
  background-color: #18519a;
  cursor: pointer;
}

.page-button:active {
  background-color: #113a6e;
  cursor: pointer;
}
