.btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #14488c;
  border-radius: 0.6em;
  color: #14488c;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.btn:hover {
  box-shadow: 0 0 40px 40px "red" inset;
  border: 2px solid #0e2f59;
  background-color: #e9f1fc;
  outline: 0;
}

.btn:active {
  color: black;
  background-color: #639de9;
}
