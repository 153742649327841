.response-bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-box-response {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  font-weight: 600;
  margin-bottom: 1rem;
}

.input-label-response {
  margin-right: 0.5rem;
  text-align: left;
  width: 50px;
}

.response-select {
  width: 10%;
  padding: 0.5rem;
}
